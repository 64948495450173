<template>
  <div>
    <div class="title">Üveges sörök</div>
    <div class="spinner" v-if="isLoading">
      <b-spinner></b-spinner>
    </div>

    <div v-if="bottle_beers.length > 0" class="items">
      <div v-for="beer in bottle_beers" :key="beer[0]">
        <item
          :title="beer[0]"
          :description="beer[1]"
          :price="beer[2]"
          :image_url="beer[3]"
        ></item>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  async mounted() {
    this.isLoading = true;
    const response = await fetch(
      "https://europe-central2-perem-340414.cloudfunctions.net/getPeremEtel?tab=uveges_sorok"
    );
    const bottle_beers = await response.json();
    this.bottle_beers = bottle_beers.items;
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: false,
      bottle_beers: [],
    };
  },
};
</script>

<style></style>
